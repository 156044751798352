const routePaths = {
  MAIN: '/',
  SERVER_UNAVAILABLE: '/server-unavailable',

  // Onboard routes
  SIGN_IN: '/sign-in',
  SIGN_IN_CONFIRMATION_REDIRECT: '/sign-in/confirmation/:service',
  SIGN_IN_CONFIRMATION_SERVICE: '/sign-in/confirmation-service/:service',
  SIGN_OUT: '/sign-out',

  REGISTER: '/register',
  REGISTER_CONFIRMATION: '/register/confirmation/:token',

  RECOVER_ACCOUNT: '/recover-account',
  RECOVER_PASSWORD: '/recover-password',
  RECOVER_PASSWORD_CONFIRMATION: '/recover-password/confirmation/:token',

  EMAIL_CONFIRMATION: '/email-confirmation',

  INVITE_CONFIRMATION: '/invite-confirmation/:token',
  ONBOARD: '/onboard',
  ONBOARD_FINISH: '/onboard-finish',

  // System routes
  DASHBOARD: '/dashboard',
  SETTINGS: '/settings',

  USERS: '/users',
  USER: '/user/:slug',
  PROFILE: '/profile',
  PROFILE_EDIT_IMAGE: '/profile/edit-image',

  BILLING: '/billing',

  WHITELABELS: '/whitelabels',
  WHITELABEL: '/whitelabel/:slug',

  ACCOUNTS: '/accounts',
  ACCOUNT: '/account/:slug',

  COMPANIES: '/companies',
  COMPANY: '/company/:slug',

  SUBSIDIARIES: '/subsidiaries',
  SUBSIDIARY: '/subsidiary/:slug',

  ACQUIRER: '/acquirer/:slug',

  CONFIGURATION: '/configuration/:slug',

  DEVICE: '/device/:slug',
  DEPARTMENT: '/department/:slug',

  INVOICES: '/invoices',
  INVOICE: '/invoice/:slug/:tpEmis',

  FINANCIAL: '/financial',
  TRANSACTIONS: '/transactions',
  TRANSACTION: '/transaction/:slug',
  TRANSACTION_DUPLICATE_DETAIL: '/duplicate/:nParc/:nDup',

  RULES_ENGINES: '/rules-engines',
  RULES_ENGINE: '/rules-engine/:accountId/:slug',

  SERIE: '/serie/:slug',

  TERMINAL: '/terminal/:slug',

  PARTICIPANT: '/participant/:slug',

  TAX_EFD_ICMS_IPI: '/tax-efd-icms-ipi/:slug',
  TAXS_EFD_ICMS_IPI: '/taxs-efd-icms-ipi',
  ASCERTAINMENT_TAX: '/ascertainment-tax/:type',

  TAX_EFD_CONTRIBUTIONS: '/tax-efd-contributions/:slug',
  TAXS_EFD_CONTRIBUTIONS: '/taxs-efd-contributions',

  REAL_ESTATE_ACTIVITY: '/real-estate-activity/:slug',
  REAL_ESTATE_DEVELOPMENT_DETAIL: '/real-estate-development/:slug',
  COSTS_INCURRED_DETAIL: '/costs-incurred/:slug',
  BUDGETED_COSTS_DETAIL: '/budgeted-costs/:slug',
  EXTRA_ASCERTAINMENT: '/extra-ascertainment/:slug',
  CONTRIBUTIONS_WITHHELD_SOURCE_DETAIL: '/contributions-withheld-source/:slug',
  MISCELLANEOUS_DEDUCTIONS_DETAIL: '/miscellaneous-deductions/:slug',
  TAX_CREDITS: '/tax-credits/:slug/:contribution',
  WITHHELD_VALUES: '/withheld-values/:slug/:contribution',
  LAWSUIT: '/lawsuit/:slug',
  CONTRIBUTIONS_SUSPENDED_REQUIREMENT: '/contributions-suspended-requirement/:slug',

  ASCERTAINMENTS: '/ascertainments',
  ASCERTAINMENT_SUBSIDIARY: '/ascertainment-subsidiary',
  ASCERTAINMENT_TAXES: '/ascertainment-taxes',
  TAX: '/tax/:slug/:type',
  CALCULATION_DETAIL: '/calculation',

  ACCOUNTING_ECD: '/accounting-ecd/:slug',
  ACCOUNTINGS_ECD: '/accountings-ecd',
  ECD_OTHERS_INFORMATIONS: '/ecd-others-informations/:slug',
  ECD_SIGNATORY: '/ecd-sigantory/:slug',
  ECD_ECONOMIC_CONGLOMERATE: '/ecd-economic-conglomerate/:slug',
  ECD_PARTICIPANT: '/ecd-participant/:slug',
  ECD_ACCOUNTS_PLAN: '/ecd-accounts-plan/:slug',
  ECD_CONSOLIDATED_ACCOUNTS_PLAN: '/ecd-consolidated-accounts-plan/:slug',
  ECD_CONSOLIDATED_ACCOUNT_BALANCES: '/ecd-consolidated-account-balances/:slug',
  ECD_COMPANIES_HOLDING_THE_PARCELS: '/ecd-companies-holding-the-parcels/:slug',
  ECD_CONSOLIDATED_COMPANIES_RELATIONS: '/ecd-consolidated-companies-relations/:slug',
  ECD_CORPORATE_EVENTS_RELATIONS: '/ecd-corporate-events-relations/:slug',
  ECD_DLPA_DMPL: '/ecd-dlpa-dmpl/:slug/:type',
  ECD_DLPA_DMPL_STATEMENT: '/ecd-dlpa-dmpl-statement/:slug',
  ECD_BALANCE_SHEET: '/ecd-balance-sheet/:slug',
  ECD_BALANCE_SHEET_STATEMENT: '/ecd-balance-sheet-statement/:slug',
  ECD_DRE: '/ecd-dre/:slug',
  ECD_DRE_STATEMENT: '/ecd-dre-statement/:slug',

  ACCOUNTING_ECF: '/accounting-ecf/:slug',
  ACCOUNTINGS_ECF: '/accountings-ecf',
  IDENTIFICATION_OF_MEMBERS_OR_HOLDERS: '/identification-of-members-or-holders/:slug',
  ECF_ASCERTAINMENT_DETAIL: '/ascertainment/:slug/:type',
  ACCOUNT_IDENTIFICATION: '/account-identification/:slug',
  LAUNCHES_WITHOUT_REFLECTIONS: '/launches-without-reflections/:slug',

  ACCOUNT_PLAN: '/accounting-chart/:slug',
  ACCOUNT_PLAN_LIST: '/accounting-charts',
  ACCOUNT_PLAN_ACCOUNT_DETAILED: '/accounting-code/:slug',

  DIARIES: '/diaries',
  BALANCES: '/patrimonial-trial-balances',
  BALANCE: '/patrimonial-trial-balance/:slug',
  ACCOUNT_BALANCE: '/patrimonial-trial-balance-account/:slug',

  INCOME_STATEMENTS: '/dre-trial-balances',
  INCOME_STATEMENT: '/dre-trial-balance/:slug',
  ACCOUNT_INCOME_STATEMENTS: '/dre-trial-balance-account/:slug',

  COMPREHENSIVE_RESULTS: '/dra',
  COMPREHENSIVE_RESULT: '/dra/:slug',
  ACCOUNT_COMPREHENSIVE_RESULTS: '/dra-account/:slug',

  ADDED_VALUES: '/dva',
  ADDED_VALUE: '/dva/:slug',
  ACCOUNT_ADDED_VALUES: '/dva-account/:slug',

  CASH_FLOWS: '/dfc',
  CASH_FLOW: '/dfc/:slug',
  ACCOUNT_CASH_FLOWS: '/dfc-account/:slug',

  BALANCES_SHEETS: '/trial-balances',
  BALANCE_SHEETS: '/trial-balance/:slug',
  BALANCE_SHEET: '/trial-balance-account/:slug',

  LEDGERS: '/ledgers',
  LEDGER: '/ledger/:slug',
  LEDGER_ACCOUNTS: '/ledger-accounts',
  ACCOUNTING_ENTRIES_LEDGER: '/ledger-account/:slug',

  HERITAGE_MUTATIONS: '/heritage-mutations',
  HERITAGE_MUTATION: '/heritage-mutation/:slug',
  ACCOUNT_HERITAGE_MUTATIONS: '/heritage-trial-balance-account/:slug',

  ACCOUNTING_ENTRIES_DIARY: '/entries/:slug',
  ACCOUNTING_ENTRIES_BALANCE: '/entries-balance/:slug',
  DIARY: '/diary/:slug',

  ITEM: '/entries-items/:slug',

  CATEGORY: '/category/:slug',
  RESULT_CENTER_ACCOUNT_DETAILED: '/result-center-account/:slug',

  PRODUCT: '/product/:slug',

  SIGNATORY: '/signatory/:slug',

  WEBHOOK: '/webhook/:slug',
  WEBHOOKS: '/webhooks',
  WEBHOOK_DETAILED: '/deliveries/:slug',

  NOT_FOUND: '*',

  TAB_SUB_ROUTE: '/tab/:tab',
  ACTION_SUB_ROUTE: '/:action/:actionId?',

  CERTIFICATE: '/certificate',
  CERTIFICATES: '/certificates',

  MAILBOX: '/mailbox',
  MAILBOXS: '/mailboxs',

  TAX_MENU: '/tax-menu',
}

export default routePaths
