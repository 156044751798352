import React from 'react'
import { CCardBody } from '@coreui/react'
import LoadingData from 'components/loading/LoadingData'

interface ICardBody extends CCardBody {
  isLoading?: boolean
  onChange?: () => void
  isFetching?: boolean
  shouldCentralizeIsLoading?: boolean
}

const CardBody: React.FC<ICardBody> = ({
  children, isLoading, onChange, isFetching, shouldCentralizeIsLoading, ...props
}) => {
  return (
    <CCardBody onChange={onChange} {...props}>
      {(isLoading || isFetching)
        ? <LoadingData shouldCentralize={shouldCentralizeIsLoading} />
        : children}
    </CCardBody>
  )
}

export default CardBody
