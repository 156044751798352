import validators from '.'

export const mandatory = {
  required: 'Obrigatório',
  validate: null
}

export const ipRegex = {
  pattern: {
    value: /^(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])(\.(?!$)|$)){4}$/,
    message: 'IP Inválido'
  }
}

export const phoneRegex = {
  pattern: {
    value: /^[0-9]{2}[0-9]{4}[0-9]{4}$/,
    message: 'Telefone Inválido'
  }
}

export const cepRegex = {
  pattern: {
    value: /^\d{5}-\d{3}$/,
    message: 'CEP Inválido'
  }
}

export const cnpjRegex = {
  pattern: {
    value: /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
    message: 'CNPJ Inválido'
  }
}

export const emailValidation = {
  ...mandatory,
}

export const emailValidationSignIn = {
  ...mandatory,
}

export const cpfValidation = {
  ...mandatory,
  validate: {
    validations: validators.cpf
  },
}

export const cnpjValidation = {
  ...mandatory,
  validate: {
    validations: validators.cnpj
  },
}

export const codeValidation = {
  ...mandatory,
  validate: {
    validations: validators.code
  },
}

export const passwordValidation = {
  ...mandatory,
  minLength: {
    value: 6,
    message: 'Mínimo 6 caracteres'
  },
}

export const ieValidation = {
  maxLength: {
    value: 14,
    message: 'Máximo 14 caracteres'
  },
  minLength: {
    value: 2,
    message: 'Mínimo 2 caracteres'
  }

}

export const imValidation = {
  maxLength: {
    value: 14,
    message: 'Máximo 14 caracteres'
  },
  minLength: {
    value: 2,
    message: 'Mínimo 2 caracteres'
  }
}
