import { CCardHeader } from '@coreui/react'
import { Spinner } from 'components/spinner'
import CardTitle from './CardTitle'

interface ICardHeader {
  isLoading?: boolean
  title?: React.ReactNode
  subtitle?: React.ReactNode
  className?: string
  classNameTitle?: string
  noCardHeaderCustom?: boolean
}

const CardHeader: React.FC<ICardHeader> = ({
  isLoading, title, subtitle, children, className, classNameTitle, noCardHeaderCustom
}) => {
  return (
    <CCardHeader className={`${noCardHeaderCustom ? '' : 'card-header-custom'} ${className || ''}`}>
      <div>
        <CardTitle title={title} className={classNameTitle} />
        {subtitle}
      </div>
      <div className={noCardHeaderCustom ? '' : 'd-flex align-items-center flex-row-reverse'}>
        {children}
        {isLoading ? <Spinner color="dark" className="float-right mr-2" /> : ''}
      </div>
    </CCardHeader>
  )
}

export default CardHeader
